<template>
  <div id="product-block">
    <div class="content-wrapper">
      <div
        class="content-container"
      >
        <div class="content">
          <div class="results">
            <router-link
              v-for="(product, index) in getResults"
              :key="product.id"
              :to="productRoute(product)"
              class="product-card-wrapper"
            >
              <ProductCard
                v-if="index < 10 && !viewAllList"
                class="product-card"
                :product="product"
                :is-market-place="true"
                :my-stock-room-product="true"
              />
              <ProductCard
                v-if="viewAllList"
                class="product-card"
                :product="product"
                :is-market-place="true"
                :my-stock-room-product="true"
              />
            </router-link>
          </div>
        </div>
        <div
          v-if="numberOfPages > 1 && typeOfProduct === 'similarProducts'"
          class="pagination-wrapper"
        >
          <BasePagination
            v-if="viewAllList"
            :pages="numberOfPages"
            :current-page="currentPage"
            @previous="prevSimilarPage"
            @next="nextSimilarPage"
            @page-clicked="setSimilarPage"
          />
        </div>
        <div
          v-if="numberOfPages > 1 && typeOfProduct === 'bestSeller'"
          class="pagination-wrapper"
        >
          <BasePagination
            v-if="viewAllList"
            :pages="numberOfPages"
            :current-page="currentPage"
            @previous="prevBesrSellerPage"
            @next="nextBesrSellerPage"
            @page-clicked="setBesrSellerPage"
          />
        </div>
        <div
          v-if="numberOfPages > 1 && typeOfProduct === 'recentlyView'"
          class="pagination-wrapper"
        >
          <BasePagination
            v-if="viewAllList"
            :pages="numberOfPages"
            :current-page="currentPage"
            @previous="prevRecentViewPage"
            @next="nextRecentViewPage"
            @page-clicked="setRecentViewPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BasePagination from '@/components/common/BasePagination.vue';
import ProductCard from '@/components/marketplace/common/ProductCard.vue';

export default {
  name: 'MarketplaceProductsBlock',
  components: {
    BasePagination,
    ProductCard,
  },
  props: {
    viewAllList: {
      type: Boolean,
      required: true,
    },
    getResults: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    numberOfPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    typeOfProduct: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
    }),
  },
  created() {
  },
  methods: {
    ...mapActions({
      nextSimilarPage: 'marketplaceSearch/nextPage',
      prevSimilarPage: 'marketplaceSearch/prevPage',
      setSimilarPage: 'marketplaceSearch/commitPage',

      nextBesrSellerPage: 'nextBesrSellerPage',
      prevBesrSellerPage: 'prevBesrSellerPage',
      setBesrSellerPage: 'commitBesrSellerPage',

      nextRecentViewPage: 'nextRecentViewPage',
      prevRecentViewPage: 'prevRecentViewPage',
      setRecentViewPage: 'commitRecentViewPage',
    }),
    productRoute(p) {
      const { vin, slug, categorySlug } = p;
      return {
        name: 'Product',
        params: { vin, slug, categorySlug },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.product-card {
    width: 228px;
}
.loading {
  height: 60px;
  width: 100%;
  position: absolute;
  top: 0;
}
.price-range {
  padding: 20px 30px 10px 30px;
  background: $white;
  border-radius: 12px;
}
.filter-label {
  font-size: .8em;
  margin: 0 -10px;
  color: $simpatra-blue;
}
.content-container {
  max-width: 1200px;
  margin: 0 auto;
}

.content {
  display: flex;
}
.results {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  width: min-content;
}
.product-card-wrapper {
  margin: 0px 0px 10px 10px;
  text-decoration: none;
}
.pagination-wrapper {
  margin-top: 25px;
  margin-bottom: 25px;
}
@media screen and (max-width: 1020px) {
  .results {
    margin-left: 0;
    justify-content: center;
  }
  .viewing {
    font-size: .8em;
  }
}
</style>
