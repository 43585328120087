<template lang="html">
  <div
    id=""
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <BaseListCard
      class="item"
      @list-card-click="routeToProductDetails(route)"
    >
      <template v-slot:left>
        <div
          class="left"
        >
          <div class="product-info-container">
            <div
              v-if="productHasImage"
              class="image-wrapper"
            >
              <img
                :src="mainImageUrl"
                class="image"
              >
            </div>
            <div
              v-else
              class="image-wrapper"
            >
              <img
                :src="categoryImage(product.categoryName)"
                :alt="product.categoryName"
                class="image"
              >
            </div>
            <div class="product-details">
              <div class="product-name">
                <span class="name">{{ product.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <div
          class="right"
          @click.stop="stopClick"
        >
          <BaseButton
            v-if="hover && product.inStock && !product.registrationRequired"
            class="sidebar-btn"
            type="secondary"
            @click="addOneToCart"
            @mouseover="hover = true"
            @mouseleave="hover = false"
          >
            Add To Cart
          </BaseButton>
          <BaseButton
            v-if="hover && !product.inStock"
            class="sidebar-btn"
            @mouseover="hover = true"
            @mouseleave="hover = false"
          >
            Out Of Stock
          </BaseButton>
          <span class="price">
            ${{ product.price.toFixed(2) }}
          </span>
        </div>
      </template>
    </BaseListCard>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { Message } from 'element-ui';
import BaseListCard from '@/components/common/BaseListCard.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import { findCategoryImage } from '@/utils/imagePlaceholder';

export default {
  name: 'MyStockRoomProduct',
  components: {
    BaseListCard,
    BaseButton,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    route: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
      show: false,
      selectedIndex: 0,
      selectedProductId: 0,
      editable: false,
    };
  },
  computed: {
    ...mapGetters({
      getProduct: 'marketplaceProduct/getProduct',
    }),
    productHasImage() {
      if (this.product.images) {
        return this.product.images.length > 0;
      }
      return false;
    },
    mainImageUrl() {
      const mainImage = this.product.images.find((i) => {
        return i.mainImage;
      });
      if (mainImage !== undefined) {
        return mainImage.imageLocation;
      }
      return '';
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      addToCart: 'marketplaceSearch/addToCart',
      fetchProductById: 'marketplaceProduct/fetchProductById',
      setEmptyCart: 'setEmptyCart',
      addMinQtyToCart: 'marketplaceSearch/addMinQtyToCart',
    }),
    stopClick() {},
    routeToProductDetails(route) {
      this.$router.push({ path: `/marketplace/${route.params.categorySlug}/${route.params.vin}/${route.params.slug}` }).catch(() => {});
    },
    categoryImage(c) {
      return findCategoryImage(c);
    },
    async addOneToCart() {
      await this.fetchProductById(this.product.id);

      if (this.getProduct.minQtyForPurchase > 1) {
        await this.addMinQtyToCart({
          productId: this.product.id,
          productPricingId: this.product.productPricingId,
          qty: this.getProduct.minQtyForPurchase,
          productName: this.product.name,
        });
      } else {
        await this.addToCart({
          productId: this.product.id,
          productPricingId: this.product.productPricingId,
        }).then(() => {
          this.setEmptyCart(false);
          Message({
            type: 'success',
            showClose: true,
            message: `The product ${this.product.name} successfully added into the cart.`,
          });
        }).catch((e) => {
          Message({
            type: 'error',
            showClose: true,
            message: e,
          });
        });
      }
    },
  },
};
</script>
<style scoped>
.sidebar-btn {
  margin-right: 20px;
}
.name {
    font-size: 14px;
    color: #374458;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.price {
    font-weight: 600;
    color: black;
}
.item:hover{
 box-shadow: 2px 2px 0px #e0dbdb;
}

.right {
  display: flex;
  align-items: center;
}
.order-id {
    font-size: 16px;
    font-weight: 600;
    color: #989898;
    padding: 5px;
 }
 .image {
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
}
.image-wrapper {
  width: 65px;
  height: 60px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
 .product-name {
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
    font-size: 14px;
 }
 .product-info-container {
     display: flex;
 }
 .product-details {
     margin-left: 20px;
     font-size: 14px;
     width: 300px;
 }
</style>
