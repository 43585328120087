<template>
  <div
    id="mystock-room-grid"
    class="mystock-room-grid"
  >
    <div
      v-if="!similarProduct && !bestSeller"
      :class="{'show-grid':gridIcon}"
      class="header"
    >
      <div class="title">
        <h3>Recently Viewed</h3>
        <div class="el-icon">
          <img
            v-if="!gridIcon"
            src="@/assets/grid-grey.svg"
            class="grid-icon el-icon-menu"
            :class="{'active-icon':gridIcon, 'in-active-icon':listIcon}"
            @click="routeGridList('grid')"
          >
          <img
            v-if="gridIcon"
            src="@/assets/grid-active.svg"
            class="grid-icon el-icon-menu"
            :class="{'active-icon':gridIcon, 'in-active-icon':listIcon}"
            @click="routeGridList('grid')"
          >
          <img
            v-if="!listIcon"
            src="@/assets/list-grey.svg"
            class="list-icon el-icon-s-fold"
            :class="{'active-icon':listIcon, 'in-active-icon':gridIcon}"
            @click="routeGridList('list')"
          >
          <img
            v-if="listIcon"
            src="@/assets/list-active.svg"
            class="list-icon el-icon-s-fold"
            :class="{'active-icon':listIcon, 'in-active-icon':gridIcon}"
            @click="routeGridList('list')"
          >
        </div>
      </div>
      <div
        v-if="!recentlyView && getRecentViewResults.length > 9"
        class="recent-view-label"
      >
        <label
          class="view-list"
          @click="viewAllProducts('recently-view')"
        >View All</label>
      </div>
      <span
        v-if="recentlyView"
        class="viewing"
      >
        Viewing {{ getRecentViewResults.length }} out of
        <span class="weighted">
          {{ getAllRecentResults.length }}
          results
        </span>
      </span>
      <BaseButton
        v-if="recentlyView"
        icon="el-icon-back"
        class="back-button"
        @click="backToHomePage"
      >
        Back
      </BaseButton>
    </div>
    <MarketplaceProductsBlock
      v-if="getRecentViewResults.length > 0"
      v-show="!similarProduct && !bestSeller"
      :view-all-list="recentlyView"
      :get-results="getRecentViewResults"
      :loading="recentViewLoading"
      :number-of-pages="getNumberOfRecentViewPages"
      :current-page="currentRecentViewPage"
      :type-of-product="'recentlyView'"
    />
    <div
      v-if="getRecentViewResults.length === 0 && !similarProduct && !recentlyView && !bestSeller"
      class="no-data"
    >
      <BaseNoData
        class="empty"
      >
        You have not viewed any items yet!
      </BaseNoData>
    </div>
    <div
      v-if="!similarProduct && !recentlyView"
      :class="{'view-all-active': bestSeller}"
      class="best-seller"
    >
      <div class="title">
        <!-- <h3>Best Seller</h3>
        <div
          v-if="!bestSeller && getBestSellerResults.length > 0"
          class="view-label">
          <label
            class="view-list"
            @click="viewAllProducts('best-seller')">View All</label>
        </div> -->
        <span
          v-if="bestSeller"
          class="viewing-result"
        >
          Viewing {{ getBestSellerResults.length }} out of
          <span class="weighted">
            {{ getAllBestResults.length }}
            results
          </span>
        </span>
        <BaseButton
          v-if="bestSeller"
          icon="el-icon-back"
          class="back-button"
          @click="backToHomePage"
        >
          Back
        </BaseButton>
      </div>
    </div>
    <MarketplaceProductsBlock
      v-if="bestSeller && getBestSellerResults.length > 0"
      :view-all-list="bestSeller"
      :get-results="getBestSellerResults"
      :loading="bestSellerLoading"
      :number-of-pages="getNumberOfBestSellerPages"
      :current-page="currentSellerPage"
      :type-of-product="'bestSeller'"
    />
    <!-- <MarketplaceCarousel
      v-if="!similarProduct && !recentlyView && !bestSeller && getBestSellerResults.length > 0"
      :carouselProducts="getBestSellerResults" />
    <div
      v-if="!similarProduct && !recentlyView && !bestSeller && getBestSellerResults.length === 0"
      class="no-data">
      <BaseNoData
        class="empty">
        Not Found
      </BaseNoData>
    </div> -->
    <div
      v-if="!recentlyView && !bestSeller"
      class="similar-products"
      :class="{'view-all-active': similarProduct}"
    >
      <div class="title">
        <!-- <h3>Similar Products</h3>
        <div
          v-if="!similarProduct && getSimilarResults.length > 0"
          class="view-label">
          <label
            class="view-list"
            @click="viewAllProducts('similar-products')">View All</label>
        </div> -->
        <span
          v-if="similarProduct"
          class="viewing-result"
        >
          Viewing {{ getSimilarResults.length }} out of
          <span class="weighted">
            {{ getAllSimilarResults.length }}
            results
          </span>
        </span>
        <BaseButton
          v-if="similarProduct"
          icon="el-icon-back"
          class="back-button"
          @click="backToHomePage"
        >
          Back
        </BaseButton>
      </div>
    </div>
    <MarketplaceProductsBlock
      v-if="similarProduct"
      :view-all-list="similarProduct"
      :get-results="getSimilarResults"
      :loading="false"
      :number-of-pages="getNumberOfSimilarPages"
      :current-page="currentSimilarPage"
      :type-of-product="'similarProducts'"
    />
    <!-- <MarketplaceCarousel
      v-if="!similarProduct && !recentlyView && !bestSeller && getSimilarResults.length > 0"
      :carouselProducts="getSimilarResults" />
    <div
      v-if="!similarProduct && !recentlyView && !bestSeller && getSimilarResults.length === 0"
      class="no-data">
      <BaseNoData
        class="empty">
        Not Found
      </BaseNoData>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import MarketplaceCarousel from '@/components/marketplace/common/MarketplaceCarousel.vue';
import MarketplaceProductsBlock from '@/components/marketplace/common/MarketplaceProductsBlock.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import BaseNoData from '@/components/common/BaseNoData.vue';

export default {
  name: 'MarketplaceMyStockroomGrid',
  components: {
    // MarketplaceCarousel,
    MarketplaceProductsBlock,
    BaseButton,
    BaseNoData,
  },
  props: {
  },
  data() {
    return {
      gridIcon: true,
      listIcon: false,
      viewAllProductsList: false,
      similarProduct: false,
      recentlyView: false,
      bestSeller: false,
      recentViewLoading: false,
      bestSellerLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getAllRecentResults: 'getRecentViewResults',
      getAllBestResults: 'getBestSellerResults',
      getAllSimilarResults: 'marketplaceSearch/getSimilarResults',

      getRecentViewResults: 'getRecentViewPageResults',
      getBestSellerResults: 'getBestSellerPageResults',
      getSimilarResults: 'marketplaceSearch/getSimilarPageResults',

      getLoadingStatus: 'marketplaceSearch/getLoadingStatus',
      getNumberOfSimilarPages: 'marketplaceSearch/getNumberOfSimilarPages',
      getNumberOfRecentViewPages: 'getNumberOfRecentViewPages',
      getNumberOfBestSellerPages: 'getNumberOfBestSellerPages',

      currentSimilarPage: 'marketplaceSearch/getPage',
      currentSellerPage: 'getBestSellerPage',
      currentRecentViewPage: 'getRecentViewPage',
    }),
  },
  async created() {
    this.recentViewLoading = true;
    await this.fetchRecentlyViewProducts();
    this.recentViewLoading = false;
    this.bestSellerLoading = true;
    await this.fetchBestSellerProducts();
    this.bestSellerLoading = false;
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      fetchRecentlyViewProducts: 'fetchRecentlyViewProducts',
      fetchBestSellerProducts: 'fetchBestSellerProducts',
    }),
    backToHomePage() {
      this.recentlyView = false;
      this.bestSeller = false;
      this.similarProduct = false;
    },
    viewAllProducts(type) {
      if (type === 'recently-view') {
        this.recentlyView = true;
        this.similarProduct = false;
        this.bestSeller = false;
      }

      if (type === 'similar-products') {
        this.similarProduct = true;
        this.recentlyView = false;
        this.bestSeller = false;
      }

      if (type === 'best-seller') {
        this.bestSeller = true;
        this.recentlyView = false;
        this.similarProduct = false;
      }
    },
    routeGridList(key) {
      if (key === 'grid') {
        this.gridIcon = true;
        this.listIcon = false;
        this.$router.push({ name: 'MarketplaceMyStockroomGrid' }).catch(() => {});
      }
      if (key === 'list') {
        this.listIcon = true;
        this.gridIcon = false;
        this.$router.push({ name: 'MarketplaceMyStockroomList' }).catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .no-data {
    padding: 15px
  }
  .mystock-room-grid {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .empty {
    height: 200px;
  }
  .weighted {
    font-weight: 600;
  }
  .viewing {
    padding: 25px 0px 0px 0px;
    margin-left: auto;
  }
  .viewing-result {
    padding: 5px 0px 0px 0px;
    margin-left: auto;
  }

  .el-icon {
    margin-left: 10px;
   }

.view-label {
    margin-left: auto;
    margin-right: 20px;
}

.recent-view-label {
    margin-top: 25px;
    margin-left: auto;
    margin-right: 20px;
 }

.header {
  display: flex;
}
.grid-icon {
  border-right: 1px solid lightgrey;
  cursor: pointer;
}

.list-icon {
  padding-left: 5px;
  cursor: pointer;
}

  .title {
  display: flex;
  padding: 20px;
  }
  .view-list {
    font-size: 14px;
    color: $orange;
    cursor: pointer;
}
* {
  margin: 0;
  padding: 0;
}
.product-card {
  padding: 5px;
}
.image-wrapper {
  width: 190px;
  height: 190px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.image {
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
}
.placeholder {
  width: 190px;
  height: 190px;
  background: $light-grey;
  display: flex;
  justify-content: center;
  align-items: center;
}
.placeholder-image {
  width: 75px;
}
.info-top {
  margin-top: 10px;
  overflow: hidden;
  height: 35px;
}
.name {
  font-size: .8em;
  color: $simpatra-blue;
  font-weight: 600;
  height: 32px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.info-bottom {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.price {
  color: $simpatra-blue;
  font-size: .8em;
}
.add-to-cart {
  color: $simpatra-orange;
  background: none;
  border: none;
  font-size: .8em;
  padding: 10px;
  margin: -10px;
  &:hover {
    cursor: pointer;
    background-color: #feefea;
    border-radius: 4px;
  }
  &:focus {
    outline: none;
  }
}
</style>
