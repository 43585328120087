<template lang="html">
  <div id="drawer">
    <div class="drawer-container">
      <a-drawer
        title="Product Details"
        placement="right"
        width="550"
        :destroy-on-close="true"
        :visible="visible"
        :mask-closable="false"
        :closable="true"
        :push="true"
        @close="onClose"
      >
        <div
          v-if="productHasImages"
          class="drawer-img"
        >
          <div>
            <img
              :class="{'multiple-img': otherImages.length !== 0, 'single-img': otherImages.length === 0}"
              :src="mainImage.imageLocation"
            >
          </div>
          <div
            v-if="multipleImages"
            class="other-images"
          >
            <div
              v-for="(image, index) in otherImages"
              :key="image.id"
              class="image-wrapper"
              @click="updateMainImage(image)"
            >
              <img
                v-if="index < 5"
                :src="image.imageLocation"
                :alt="getProduct.name"
                class="other-images"
              >
            </div>
          </div>
        </div>
        <div
          v-else
          class="placeholder"
        >
          <div>
            <img
              :src="categoryImage(getProduct.categoryName)"
              :alt="getProduct.categoryName"
              class="single-img"
            >
            <p class="placeholder-message">
              We're sorry, this product doesn't have any images
            </p>
          </div>
        </div>
        <div class="details">
          <h1 class="name">
            {{ getProduct.name }}
          </h1>
          <div class="origin">
            <span class="sku">
              {{ getProduct.sku }}
            </span>
            <span
              class="vendor"
            >
              {{ getVendor.name }}
            </span>
            <span class="social-wrapper">
              <ProductSocial
                :product="getProduct"
                :is-restock-details="true"
              />
            </span>
          </div>
          <div class="pricing">
            <p class="price-label">
              Price
            </p>
            <p class="price">
              ${{ getProduct.price.toFixed(2) }}
            </p>
          </div>
          <!-- <div class="tags">
            <h2 class="related-tags-header">
              Related Tags
            </h2>
            <div
              class="related-tags">
              Related
            </div>
            <div
              class="related-tags">
              Related
            </div>
            <div
              class="related-tags">
              Related
            </div>
            <div
              class="related-tags">
              Related
            </div>
          </div> -->
          <!-- <div class="highlights">
            <h2 class="highlights-header">
              Product Highlights
            </h2>
            <p class="highlights-body">
              Serrapeptase is used in medical practise in Europe and Asia
              with stunning results for more than 25 years. It is registered in
              Japan as an official medical treatment for atherosclerosis and coronary
              heart disease.
            </p>
          </div> -->
          <div class="description">
            <h2 class="description-header">
              Product Description
            </h2>
            <p class="description-body">
              {{ getProduct.description }}
            </p>
          </div>
          <el-divider />
        </div>
        <div class="toggle-btn">
          <div
            class="one-time-purchase"
            :class="{'purchase-item': purchase}"
            @click="purchaseItem"
          >
            One time purchase
          </div>
          <div
            :class="{'subscribe-item': subscribe}"
            class="subscribe"
            @click="subscribeItem"
          >
            Subscribe and Save
          </div>
        </div>
        <ProductNotify v-if="getAction === 'notify'" />
        <ProductOneTimePurchase
          v-if="purchase && getAction === 'add-to-cart'"
          :product="product"
        />
        <!-- <ProductSubscribeSave
          v-if="subscribe && getAction === 'add-to-cart'"
          :product="product" /> -->
        <div
          v-if="subscribe && getAction === 'add-to-cart'"
          style="text-align: center; padding: 20px;"
        >
          This feature will coming soon!
        </div>
      </a-drawer>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ProductSocial from '@/components/marketplace/product/ProductSocial.vue';
import ProductOneTimePurchase from '@/components/marketplace/common/ProductOneTimePurchase.vue';
// import ProductSubscribeSave from '@/components/marketplace/common/ProductSubscribeSave.vue';
import ProductNotify from '@/components/marketplace/product/ProductNotify.vue';
import { findCategoryImage } from '@/utils/imagePlaceholder';

export default {
  name: 'MarketplaceProductDetailsDrawer',
  components: {
    ProductSocial,
    ProductOneTimePurchase,
    // ProductSubscribeSave,
    ProductNotify,
  },
  props: {
    drawerProductId: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      qty: 1,
      purchase: true,
      subscribe: false,
      mainImage: {},
      mainImageUrl: 'https://simpatra-img.s3.us-west-2.amazonaws.com//product/1582365308248-muh11-min.png',
      drawer: true,
      visible: true,
      selectedOrderHistory: false,
    };
  },
  computed: {
    ...mapGetters({
      getProduct: 'marketplaceProduct/getProduct',
      getVendor: 'marketplaceVendor/getVendor',
      getAction: 'marketplaceProduct/getProductAction',
    }),
    productHasImages() {
      return this.getProduct.images.length > 0;
    },
    multipleImages() {
      if (this.getProduct.images !== undefined) {
        return this.getProduct.images.length > 1;
      } return 0;
    },
    otherImages() {
      if (this.getProduct.images !== undefined) {
        return this.getProduct.images.filter((i) => {
          return i.id !== this.mainImage.id;
        });
      } return '';
    },
  },
  watch: {
    getProduct() {
      this.mainImage = this.getProduct.images.find((i) => {
        return i.mainImage;
      });
    },
  },
  async created() {
    await this.fetchProductById(this.drawerProductId);
    await this.fetchVendor(this.getProduct.vin);
    this.mainImage = this.getProduct.images.find((i) => {
      return i.mainImage;
    });
    this.qty = this.product.qty;
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      addItemToCart: 'marketplaceProduct/addItemToCart',
      fetchProductById: 'marketplaceProduct/fetchProductById',
      fetchVendor: 'marketplaceVendor/fetchVendorByVin',
      setEmptyCart: 'setEmptyCart',
    }),
    categoryImage(c) {
      return findCategoryImage(c);
    },
    purchaseItem() {
      this.purchase = true;
      this.subscribe = false;
    },
    subscribeItem() {
      this.subscribe = true;
      this.purchase = false;
    },
    updateMainImage(image) {
      this.mainImage = image;
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
      this.$emit('closeProductDrawer');
    },
  },
};
</script>
<style lang="scss" scoped>
.placeholder-message {
  font-size: .8em;
  opacity: .5;
  margin-top: 5px;
  text-align: center;
}
  .subscribe-item {
    margin-left: -20px;
    z-index: 5;
    border-radius: 50px !important;
    background-color: $orange !important;
    color: #fff !important;
    }
    .purchase-item {
    margin-right: -20px;
    z-index: 5;
    color: #fff !important;
    background-color: $orange !important;
    border-radius: 50px !important;
    }
  .one-time-purchase {
    height: 40px;
    color: $orange;
    background-color: #fff;
    border: 1px solid $orange;
    width: 245px;
    border-radius: 50px 0px 0px 50px;
    padding: 7px 0px 0px 50px;
    cursor: pointer;
  }
  .subscribe {
    padding: 7px 0px 0px 50px;
    height: 40px;
    color: $orange;
    background-color: #fff;
    border: 1px solid $orange;
    width: 245px;
    border-radius: 0px 50px 50px 0px;
    cursor: pointer;
  }
  .toggle-btn {
      display: flex;
      margin-bottom: 25px;
  }

.text {
  margin: 0px;
  color: black;
}
.highlights {
  margin-top: 20px;
}
.related-tags {
    margin: 5px;
    padding: 5px 10px;
    background: $grey;
    border-radius: 4px;
    display: inline-block;
    font-size: .8em;
    border: 1px solid #d4d2d2;
    &:hover {
      cursor:pointer;
      background: $dark-grey;
    }
}
.other-images {
    width: 80px;
    height: 61px;
    padding-left: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}

::v-deep {
  .ant-drawer-header {
    background-color: #374458;
    border-radius: 0px;
    height: 83px;
    padding: 30px 25px;
  }
  .ant-drawer-title {
    color: #fff;
    font-weight: 600;
    font-size: 18px;
  }
  .ant-drawer-close {
    color: #fff !important;
    top: 18px;
  }
  .ant-drawer-body {
    padding-top: 25px;
    padding-left: 40px;
  }
}
.single-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.drawer-img {
  display:flex;
}
.multiple-img {
    width: 360px;
    height: 350px;
    cursor: pointer;
}
.drawer-container {
    padding: 25px;
}

.details {
  flex: 1;
}
.name {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 2em;
  color: $simpatra-blue;
  margin-top: 20px;
  line-height: 100%;
}
.origin {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}
.sku {
  font-size: .8em;
  font-weight: 600;
  margin-right: 10px;
  color: #374458;
}
.vendor {
  font-size: .7em;
  width: 150px;
  color: #989ba9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.social-wrapper {
  margin-top: -4px;
  padding-left: 10px;
}
.pricing {
  margin-bottom: 20px;
}
.price-label {
  font-weight: 600;
  font-size: .8em;
  color: #374458;
}
.price {
  font-size: 1.5em;
  font-weight: 600;
}
.attachments {
  margin-bottom: 50px;
}
.attachments-label {
  font-size: .8em;
  margin-bottom: 5px;
}
.description-header {
  font-family: 'Inter';
  font-size: 1em;
  margin-bottom: 10px;
}
.highlights-header {
  font-family: 'Inter';
  font-size: 1em;
  margin-bottom: 10px;
}
.related-tags-header {
  font-family: 'Inter';
  font-size: 1em;
  margin-bottom: 10px;
}
.description-body {
  font-size: .9em;
  line-height: 1.5em;
}
</style>
