<template lang="html">
  <div id="product-list">
    <div
      v-if="!similarProduct && !bestSeller"
      :class="{'show-grid':gridIcon}"
      class="header"
    >
      <div class="title">
        <h3>Recently Viewed</h3>
        <div class="el-icon">
          <img
            v-if="!gridIcon"
            src="@/assets/grid-grey.svg"
            class="grid-icon"
            @click="routeGridList('grid')"
          >
          <img
            v-if="gridIcon"
            src="@/assets/grid-active.svg"
            class="grid-icon"
            @click="routeGridList('grid')"
          >
          <img
            v-if="!listIcon"
            src="@/assets/list-grey.svg"
            class="list-icon"
            @click="routeGridList('list')"
          >
          <img
            v-if="listIcon"
            src="@/assets/list-active.svg"
            class="list-icon"
            @click="routeGridList('list')"
          >
        </div>
      </div>
      <div
        v-if="!recentlyView && getRecentViewResults.length > 0"
        class="recent-view-label"
      >
        <label
          class="view-list"
          @click="viewAllProducts('recently-view')"
        >View All</label>
      </div>
      <span
        v-if="recentlyView"
        class="viewing-recent-view"
      >
        Viewing {{ getRecentViewResults.length }} out of
        <span class="weighted">
          {{ getAllRecentResults.length }}
          results
        </span>
      </span>
      <BaseButton
        v-if="recentlyView"
        icon="el-icon-back"
        class="back-button"
        @click="backToHomePage"
      >
        Back
      </BaseButton>
    </div>
    <div v-if="getRecentViewResults.length > 0">
      <router-link
        v-for="(product, index) in getRecentViewResults"
        :key="product.id"
        :to="productRoute(product)"
        class="router-link"
      >
        <MyStockRoomProduct
          v-if="index < 3 && !recentlyView && !similarProduct && !bestSeller"
          :route="productRoute(product)"
          :product="product"
        />
        <MyStockRoomProduct
          v-if="recentlyView"
          :product="product"
          :route="productRoute(product)"
        />
      </router-link>
    </div>
    <div
      v-if="getRecentViewResults.length === 0 && !similarProduct && !recentlyView && !bestSeller"
      class="no-data"
    >
      <BaseNoData
        class="empty"
      >
        You have not viewed any items yet!
      </BaseNoData>
    </div>
    <div
      v-if="!similarProduct && !recentlyView"
      :class="{'view-all-active': bestSeller}"
      class="title"
    >
      <!-- <h3>Best Seller</h3>
      <div
        v-if="!bestSeller && getBestSellerResults.length > 0"
        class="view-label">
        <label
          class="view-list"
          @click="viewAllProducts('best-seller')">View All</label>
      </div> -->
      <span
        v-if="bestSeller"
        class="viewing"
      >
        Viewing {{ getBestSellerResults.length }} out of
        <span class="weighted">
          {{ getAllBestResults.length }}
          results
        </span>
      </span>
      <BaseButton
        v-if="bestSeller"
        icon="el-icon-back"
        class="back-button"
        @click="backToHomePage"
      >
        Back
      </BaseButton>
    </div>
    <!-- <div v-if="getBestSellerResults.length > 0">
      <router-link
        v-for="(product, index) in getBestSellerResults"
        :key="product.id"
        :to="productRoute(product)"
        class="router-link">
        <MyStockRoomProduct
          v-if="index < 3 && !recentlyView && !similarProduct && !bestSeller"
          :product="product" />
        <MyStockRoomProduct
          v-if="bestSeller"
          :product="product" />
      </router-link>
    </div>
    <div
      v-if="getBestSellerResults.length === 0 && !similarProduct && !recentlyView && !bestSeller"
      class="no-data">
      <BaseNoData
        class="empty">
        Not found
      </BaseNoData>
    </div> -->
    <div
      v-if="!recentlyView && !bestSeller"
      :class="{'view-all-active': similarProduct}"
      class="title"
    >
      <!-- <h3>Similar Products</h3>
      <div
        v-if="!similarProduct && getSimilarResults.length > 0"
        class="view-label">
        <label
          class="view-list"
          @click="viewAllProducts('similar-products')">View All</label>
      </div> -->
      <span
        v-if="similarProduct"
        class="viewing"
      >
        Viewing {{ getSimilarResults.length }} out of
        <span class="weighted">
          {{ getAllSimilarResults.length }}
          results
        </span>
      </span>
      <BaseButton
        v-if="similarProduct"
        icon="el-icon-back"
        class="back-button"
        @click="backToHomePage"
      >
        Back
      </BaseButton>
    </div>
    <!-- <div v-if="getSimilarResults.length > 0">
      <router-link
        v-for="(product, index) in getSimilarResults"
        :key="product.id"
        :to="productRoute(product)"
        class="router-link">
        <MyStockRoomProduct
          v-if="index < 3 && !recentlyView && !similarProduct && !bestSeller"
          :product="product" />
        <MyStockRoomProduct
          v-if="similarProduct"
          :product="product" />
      </router-link>
    </div>
    <div
      v-if="getSimilarResults.length === 0 && !similarProduct && !recentlyView && !bestSeller"
      class="no-data">
      <BaseNoData
        class="empty">
        Not found
      </BaseNoData>
    </div> -->
    <div
      v-if="(getNumberOfRecentViewPages > 1 && recentlyView)"
      class="pagination-wrapper"
    >
      <BasePagination
        :pages="getNumberOfRecentViewPages"
        :current-page="currentRecentViewPage"
        @previous="prevRecentViewPage"
        @next="nextRecentViewPage"
        @page-clicked="setRecentViewPage"
      />
    </div>
    <div
      v-if="(getNumberOfBestSellerPages > 1 && bestSeller)"
      class="pagination-wrapper"
    >
      <BasePagination
        :pages="getNumberOfBestSellerPages"
        :current-page="currentSellerPage"
        @previous="prevBesrSellerPage"
        @next="nextBesrSellerPage"
        @page-clicked="setBesrSellerPage"
      />
    </div>
    <div
      v-if="(getNumberOfSimilarPages > 1 && similarProduct)"
      class="pagination-wrapper"
    >
      <BasePagination
        :pages="getNumberOfSimilarPages"
        :current-page="currentSimilarPage"
        @previous="prevSimilarPage"
        @next="nextSimilarPage"
        @page-clicked="setSimilarPage"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MyStockRoomProduct from '@/components/marketplace/common/MyStockRoomProduct.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import BasePagination from '@/components/common/BasePagination.vue';
import BaseNoData from '@/components/common/BaseNoData.vue';

export default {
  name: 'MarketplaceMyStockroomList',
  components: {
    MyStockRoomProduct,
    BaseButton,
    BasePagination,
    BaseNoData,
  },
  props: {
  },
  data() {
    return {
      isSimilarSearch: false,
      recentlyView: false,
      bestSeller: false,
      similarProduct: false,
      gridIcon: false,
      listIcon: true,
    };
  },
  computed: {
    ...mapGetters({
      getAllRecentResults: 'getRecentViewResults',
      getAllBestResults: 'getBestSellerResults',
      getAllSimilarResults: 'marketplaceSearch/getSimilarResults',

      getRecentViewResults: 'getRecentViewPageResults',
      getBestSellerResults: 'getBestSellerPageResults',
      getSimilarResults: 'marketplaceSearch/getSimilarPageResults',

      getNumberOfRecentViewPages: 'getNumberOfRecentViewPages',
      getNumberOfBestSellerPages: 'getNumberOfBestSellerPages',
      getNumberOfSimilarPages: 'marketplaceSearch/getNumberOfSimilarPages',

      currentSellerPage: 'getBestSellerPage',
      currentRecentViewPage: 'getRecentViewPage',
      currentSimilarPage: 'marketplaceSearch/getPage',
    }),
  },
  async created() {
    await this.fetchRecentlyViewProducts();
    await this.fetchBestSellerProducts();
  },
  methods: {
    ...mapActions({
      fetchRecentlyViewProducts: 'fetchRecentlyViewProducts',
      fetchBestSellerProducts: 'fetchBestSellerProducts',

      nextSimilarPage: 'marketplaceSearch/nextPage',
      prevSimilarPage: 'marketplaceSearch/prevPage',
      setSimilarPage: 'marketplaceSearch/commitPage',

      nextBesrSellerPage: 'nextBesrSellerPage',
      prevBesrSellerPage: 'prevBesrSellerPage',
      setBesrSellerPage: 'commitBesrSellerPage',

      nextRecentViewPage: 'nextRecentViewPage',
      prevRecentViewPage: 'prevRecentViewPage',
      setRecentViewPage: 'commitRecentViewPage',
    }),
    viewAllProducts(type) {
      if (type === 'recently-view') {
        this.recentlyView = true;
        this.similarProduct = false;
        this.bestSeller = false;
        this.isSimilarSearch = false;
      }
      if (type === 'best-seller') {
        this.similarProduct = false;
        this.recentlyView = false;
        this.bestSeller = true;
        this.isSimilarSearch = false;
      }
      if (type === 'similar-products') {
        this.bestSeller = false;
        this.recentlyView = false;
        this.similarProduct = true;
        this.isSimilarSearch = true;
      }
    },
    backToHomePage() {
      this.recentlyView = false;
      this.bestSeller = false;
      this.similarProduct = false;
    },
    routeGridList(key) {
      if (key === 'grid') {
        this.gridIcon = true;
        this.listIcon = false;
        this.$router.push({ name: 'MarketplaceMyStockroomGrid' }).catch(() => {});
      }
      if (key === 'list') {
        this.listIcon = true;
        this.gridIcon = false;
        this.$router.push({ name: 'MarketplaceMyStockroomList' }).catch(() => {});
      }
    },
    productRoute(p) {
      const { vin, slug, categorySlug } = p;
      return {
        name: 'Product',
        params: { vin, slug, categorySlug },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
#product-list {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.no-data {
  padding: 20px;
}
.empty {
  height: 200px;
}
.view-all-active {
  // margin-top: -34px;
}
.weighted {
    font-weight: 600;
  }
.viewing {
    padding: 8px 0px 0px 0px;
    margin-left: auto;
  }

  .viewing-recent-view {
    padding: 25px 0px 0px 0px;
    margin-left: auto;
  }
.pagination-wrapper {
  margin-top: 25px;
  margin-bottom: 25px;
}
.back-button {
    // margin-left: auto;
    // margin-right: 15px;;
}
.el-icon {
  margin-left: 10px;
  // padding: 18px 0px 0px 10px;
}
.header {
  display: flex;
}
.active-icon {
  color: $orange;
}
.in-active-icon {
  color: lightgrey;
}
.grid-icon {
  border-right: 1px solid lightgrey;
  cursor: pointer;
}
.list-icon {
  padding-left: 5px;
  cursor: pointer;
}
.router-link {
  text-decoration: none;
}
.title {
  display: flex;
  padding: 20px;
}
.view-label {
    margin-left: auto;
    margin-right: 20px;
}

.recent-view-label {
    margin-top: 25px;
    margin-left: auto;
    margin-right: 20px;
 }
 .view-list {
    font-size: 14px;
    color: $orange;
    cursor: pointer;
}
.qty-input-container {
  display: flex;
  width: 140px;
  margin-left: 15px;
  justify-content: flex-end;
}
.qty-update {
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f05a28;
  opacity: 5;
  transition: all .2s ease;
  margin: 0px 5px;
}
.subtotal-wrapper {
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
}
.subtotal {
  font-weight: 600;
  padding: 5px 10px;
  background-color: #ebeef5;
  border-radius: 8px;
}
.price, .qty, .subtotal {
  font-size: 16px;
  color: dark-blue;
}
.vl {
  border-left: 1px solid #989898;;
  height: 50px;
  margin-right: 10px;
}
.order-date {
    font-size: 14px;
    color: #989898;
}
.price {
    font-weight: 600;
}
/* .right {
    padding-top: 20px;
} */
.right {
  display: flex;
  align-items: center;
}
.order-id {
    font-size: 16px;
    font-weight: 600;
    color: #989898;
    padding: 5px;
 }
 .product-img {
     width: 100px;
 }
 .product-name {
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
        font-size: 14px;
 }
 .product-info-container {
     display: flex;
 }
 .order-details {
     margin-left: 20px;
     font-size: 14px;
 }
</style>
